import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Button,
  Form,
  Input,
  Col,
  Row,
  Alert,
  Tabs,
  Switch,
  Table,
  Space,
} from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";

import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { url } from "inspector";

const { TextArea } = Input;

const EditClientes = () => {
  const { saveCliente, currentCliente, setCurrentCliente } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loading, setLoading] = useState<boolean>(false);
  const [ativo, setAtivo] = useState<boolean | null>(null);
  const [ipInput, setIpInput] = useState<any>("");
  const [ips, setIps] = useState<any>([]);
  const [urlInput, setUrlInput] = useState<any>("");
  const [urls, setUrls] = useState<any>([]);

  const addIp = () => {
    if (ipInput.trim()) {
      setIps([...ips, ipInput.trim()]);
      setIpInput("");
    }
  };

  const removeIp = (index: number) => {
    setIps(ips.filter((_: any, i: number) => i !== index));
  };

  const addUrl = () => {
    if (urlInput.trim()) {
      setUrls([...urls, urlInput.trim()]);
      setUrlInput("");
    }
  };

  const removeUrl = (index: number) => {
    setUrls(urls.filter((_: any, i: number) => i !== index));
  };

  const onFinish = (values: any) => {
    setLoading(true);
    saveCliente(
      {
        razao_social: values.cliente.razaoSocial,
        representante: values.cliente.representante,
        email: values.cliente.email,
        telefone: values.cliente.telefone,
        obs: values.cliente.obs,
        cnpj: values.cliente.cnpj,
        inativo:
          ativo !== null ? (ativo ? false : true) : currentCliente.inativo,
        ips,
        urls,
        descricao: values.descricao,
      },
      currentCliente?._id
    )
      .then((res) => setCurrentCliente(res))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (currentCliente?._id) {
      currentCliente.ips && setIps(currentCliente.ips);
      currentCliente.urls && setUrls(currentCliente.urls);
    }
  }, [currentCliente]);
  return (
    <div className="editClientes">
      <BackHeader title={"Editar Cliente"} />
      {currentCliente?._id && (
        <Form layout="vertical" onFinish={onFinish}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Dados gerais",
                children: (
                  <div style={{ overflowX: "hidden" }}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name={["cliente", "inativo"]} label="Ativo">
                          <Switch
                            defaultChecked={!currentCliente.inativo}
                            checked={
                              ativo !== null
                                ? ativo
                                  ? true
                                  : false
                                : !currentCliente.inativo
                                ? true
                                : false
                            }
                            checkedChildren="Sim"
                            unCheckedChildren="Não"
                            onChange={(v) => setAtivo(v)}
                            style={{
                              backgroundColor:
                                ativo !== null
                                  ? ativo
                                    ? "#52c41a"
                                    : "#ff4d4f"
                                  : !currentCliente.inativo
                                  ? "#52c41a"
                                  : "#ff4d4f",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={["cliente", "razaoSocial"]}
                          label="Razão Social"
                        >
                          <Input
                            value={currentCliente?.razao_social}
                            defaultValue={currentCliente?.razao_social}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name={["cliente", "cnpj"]} label="CNPJ">
                          <Input
                            value={currentCliente?.cnpj}
                            defaultValue={currentCliente?.cnpj}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name={["cliente", "email"]} label="E-mail">
                          <Input
                            value={currentCliente?.email}
                            defaultValue={currentCliente?.email}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name={["cliente", "telefone"]}
                          label="Telefone / Celular"
                        >
                          <Input
                            value={currentCliente?.telefone}
                            defaultValue={currentCliente?.telefone}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={["cliente", "representante"]}
                          label="Representante legal"
                        >
                          <Input
                            value={currentCliente?.representante}
                            defaultValue={currentCliente?.representante}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name={["cliente", "obs"]}
                          label="Observações"
                        >
                          <TextArea
                            rows={6}
                            value={currentCliente?.obs}
                            defaultValue={currentCliente?.obs}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ),
              },
            ]}
          />
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "CSA",
                children: (
                  <div style={{ overflowX: "hidden" }}>
                    <>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Space style={{ marginBottom: "1rem" }}>
                            <Input
                              style={{ width: "400px" }}
                              placeholder="Digite um IP"
                              value={ipInput}
                              onChange={(e) => setIpInput(e.target.value)}
                              onPressEnter={addIp}
                            />
                            <Button
                              type="dashed"
                              onClick={addIp}
                              icon={<PlusOutlined />}
                            >
                              Adicionar IP
                            </Button>
                          </Space>
                          <Table
                            size="small"
                            dataSource={ips.map(
                              (ip: string, index: number) => ({
                                key: index,
                                ip,
                              })
                            )}
                            columns={[
                              { title: "IP", dataIndex: "ip", key: "ip" },
                              {
                                title: "Ações",
                                key: "actions",
                                render: (_, record, index) => (
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={() => removeIp(index)}
                                  />
                                ),
                              },
                            ]}
                          />
                        </Col>
                        <Col span={12}>
                          <Space style={{ marginBottom: "1rem" }}>
                            <Input
                              style={{ width: "400px" }}
                              placeholder="Digite uma URL"
                              value={urlInput}
                              onChange={(e) => setUrlInput(e.target.value)}
                              onPressEnter={addUrl}
                            />
                            <Button
                              type="dashed"
                              onClick={addUrl}
                              icon={<PlusOutlined />}
                            >
                              Adicionar URL
                            </Button>
                          </Space>
                          <Table
                            size="small"
                            dataSource={urls.map(
                              (url: string, index: number) => ({
                                key: index,
                                url,
                              })
                            )}
                            columns={[
                              { title: "URL", dataIndex: "url", key: "url" },
                              {
                                title: "Ações",
                                key: "actions",
                                render: (_, record, index) => (
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={() => removeUrl(index)}
                                  />
                                ),
                              },
                            ]}
                          />
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={["cliente", "descricao"]}
                            label="Descrição da atividade"
                          >
                            <Input.TextArea
                              defaultValue={currentCliente.descricao}
                              rows={3}
                              placeholder="Descreva a atividade..."
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                    <br />
                  </div>
                ),
              },
            ]}
          />
          <Form.Item>
            <Button
              loading={loading}
              icon={<SaveOutlined />}
              type="primary"
              className="btn-success"
              htmlType="submit"
            >
              Salvar cliente
            </Button>
          </Form.Item>
        </Form>
      )}
      {!currentCliente?._id && (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default EditClientes;
