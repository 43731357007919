import React, { useState, useEffect } from "react";
import { GlobalContextType } from "../@types/global";
import api from "../services/api";
import { AlertTypes } from "../@types/alert";
import { useAuth0 } from "@auth0/auth0-react";
import { message } from "antd";
import { useProcessosContext } from "./processosContext";
import { Questionario, useMapeamentoContext } from "./mapeamentoContext";
import { useTaskContext } from "./taskContext";
import { useNotificationContext } from "./notificacaoContext";
import { useCppdContext } from "./cppdContext";
import FormData from "form-data";
import { useCsaContext } from "./csaContext";

export const GlobalContext = React.createContext<GlobalContextType | null>(
  null
);

interface IProps {
  children: React.ReactNode;
}

export interface AddUserRequest {
  email: string;
  nome: string;
  grupoFuncoes: {
    // name: string;
    description: string;
    funcoes: [{ funcao_id: string; reference_model_id: string }];
  };
}
export interface Questao {
  _id: string;
  questao: string;
  ordem: number;
  tipo: string;
}

export interface VersaoRespostasTypes {
  questionario_id: Questionario;
  created_at: Date;
  updated_at: Date;
  prazo?: Date | null;
  respostas: number;
  status: "Pendente" | "Enviado" | "Aguardando respostas" | "Concluído";
}

const GlobalContextProvider: React.FC<IProps> = ({ children }) => {
  // inicialização de subcontextos
  const processosContext = useProcessosContext();
  const mapeamentoContext = useMapeamentoContext();
  const csaContext = useCsaContext();
  const taskContext = useTaskContext();
  const notificacaoContext = useNotificationContext();
  const cppdCOntext = useCppdContext();

  const { getAccessTokenSilently, logout, user, isAuthenticated } = useAuth0();

  const [loadingCount, setLoadingCount] = useState(0);
  const [timer, setTimer] = useState(null);

  const incrementLoadingCount = () => {
    setLoadingCount((prevCount) => prevCount + 1);
  };

  const decrementLoadingCount = () => {
    setLoadingCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    // Limpa o temporizador existente quando o loadingCount muda
    if (timer) {
      clearTimeout(timer);
    }

    // Se loadingCount for maior que zero, redefine o temporizador
    if (loadingCount > 0) {
      const newTimer: any = setTimeout(() => {
        setLoadingCount(0);
      }, 10000); // 10 segundos
      setTimer(newTimer);
    }

    // Limpa o temporizador quando o componente é desmontado ou loadingCount chega a zero
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loadingCount]);

  const [rote, setRote] = React.useState<any>(null);

  const [currentRoute, setCurrentRoute] = React.useState<any>();
  const [clientes, setClientes] = React.useState<any>();
  const [anexosOwasp, setAnexosOwasp] = React.useState<any>(null);
  const [usuarios, setUsuarios] = React.useState<any>();
  const [modulosPermissao, setModulosPermissao] = React.useState();
  const [permissoes, setPermissoes] = React.useState<any>();
  const [clienteAreas, setClienteAreas] = React.useState<any>([]);
  const [alert, setAlert] = React.useState<AlertTypes>();
  const [currentCliente, setCurrentCliente] = React.useState<any>();
  const [loginToken, setLoginToken] = React.useState<any>(null);
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 800 ? false : true
  );
  const [openKeys, setOpenKeys] = useState(["acaofornecedores"]);
  const [editFornecedor, setEditFornecedor] = useState<any>(null);
  const [feedbacks, setFeedbacks] = useState<any>(null);

  const [auth0Users, setAuth0Users] = useState(null);
  const [prazos, setPrazos] = useState([]);
  const [allRespFornecedor, setAllRespFornecedor] = useState([]);
  const [tomTicketOrganizacoes, setTomTicketOrganizacoes] = useState([]);

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<any>({});
  const [currentAnalise, setCurrentAnalise] = useState<any>({});
  const [tiposCliente, setTiposCliente] = useState<any>([]);
  const [tiposUser, setTiposUser] = useState<any>([]);
  const [gruposFunc, setGruposFunc] = useState<any>([]);
  const [clientesSponsor, setClientesSponsor] = useState<any>([]);
  const [clientesLider, setClientesLider] = useState<any>([]);
  const [clientesRespondentes, setClientesRespondentes] = useState<any>([]);
  const [grauRiscos, setGrauRiscos] = useState<any>([]);
  const [planoAcao, setPlanoAcao] = useState<any>([]);
  const [fornecedores, setFornecedores] = useState<any>([]);
  const [grauRiscosPergunta, setGrauRiscosPergunta] = useState<any>([]);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [funcoes, setFuncoes] = useState<any[] | null>(null);
  const [questoesFornecedores, setQuestoesFornecedores] = useState<
    Questao[] | null
  >(null);
  const [allQuestionarios, setAllQuestionarios] = useState<
    VersaoRespostasTypes[] | null
  >(null);

  useEffect(() => {
    if (userInfo._id) {
      getAll();
    }
  }, [userInfo]);

  useEffect(() => {
    if (accessToken && isAuthenticated) {
      // setUserInfo((prev: any) => ({ ...prev, sub: user?.sub }));

      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      api.defaults.headers.common["User"] = `${user?.sub}`;

      getUser();
    }
  }, [accessToken, isAuthenticated]);

  useEffect(() => {
    const tk = localStorage.getItem("tokenLogin");
    tk && setLoginToken(tk);
    // getGrauRiscos();
  }, []);

  useEffect(() => {
    localStorage.setItem("tokenLogin", loginToken);
  }, [loginToken]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        let token = await getAccessTokenSilently();

        setAccessToken(token);
      } catch (e: any) {
        if (e.error === "invalid_grant") {
          logout({
            logoutParams: {
              returnTo: "https://workplace.seusdados.com",
            },
          });
          return;
        }
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    // Remova o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // O array vazio assegura que o efeito é executado apenas uma vez

  // useEffect(() => {
  //   if (currentCliente?._id) {
  //     mapeamentoContext.getQuestionariosCliente(currentCliente?._id);
  //   }
  // }, [currentCliente]);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [pageSize, setPageSize] = useState(10); // Número padrão de itens por página
  const [pageSizeBig, setPageSizeBig] = useState(10); // Número padrão de itens por página
  const [modulos, setModulos] = useState(null);

  const calculatePageSize = () => {
    // Suponha que a altura de uma linha na tabela seja de 54 pixels (ajuste conforme necessário)
    const rowsPerPage = Math.floor((windowHeight - 300) / 54);
    const rowsPerPageBig = Math.floor((windowHeight - 300) / 120);
    setPageSize(rowsPerPage);
    setPageSizeBig(rowsPerPageBig);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    calculatePageSize();
  }, [windowHeight]);

  const getAll = async () => {
    try {
      const promises = [
        getUsuarios(),
        taskContext?.fetchTasks(),
        getQuestoesFornecedores(),
        getClientes(),
        processosContext?.getCapitulos(),
        getFuncoes(),
        getOrganizacoes(),
        getTiposCliente(),
        getGruposFunc(),
        processosContext?.getPerguntas(),
        processosContext?.getCapituloPerguntas(),
        mapeamentoContext?.getDados(),
        getGrauRiscosPergunta(),
        mapeamentoContext?.getTemplates(),
        getModulos(),
        getGrauRiscos(),
        getAllRespoForn(),
        mapeamentoContext?.getTitulares(),
        getFeedback(),
        getPrazos(),
        processosContext?.getAreas(),
      ];

      if (
        !userInfo?.grupos?.find(
          (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
        ) &&
        userInfo?.cliente_id
      ) {
        promises.push(
          mapeamentoContext?.getQuestionariosCliente(userInfo.cliente_id)
        );
      }

      if (userInfo?.grupos?.find((gp: any) => gp.name === "seusdados")) {
        promises.push(getAllQuestionarios());
      }
      // Aguarde todas as promessas serem resolvidas
      await Promise.all(promises);

      console.log("Todas as chamadas foram concluídas");
    } catch (error) {
      console.error("Erro ao executar as chamadas", error);
    }
  };

  useEffect(() => {
    if (userInfo?.grupos?.find((gp: any) => gp.name === "seusdados")) {
      getAuthUsers();
    }
  }, [userInfo]);

  const getUsuarios = () => {
    incrementLoadingCount();

    api
      .get("users")
      .then((response) => {
        setUsuarios(response.data);
      })
      .finally(() => decrementLoadingCount());
  };

  const getUser = () => {
    incrementLoadingCount();

    api
      .get("myuser")
      .then((response) => {
        setUsuarios([response.data]);

        setUserInfo(response.data);
      })
      .catch((err) => {
        setUserInfo({ error: true });
      })
      .finally(() => decrementLoadingCount());
  };

  const delegarQuestionario = (values: any) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post("delegarquestionario", values)
        .then((response) => {
          getUsuarios();
          message.success("Respondentes associados com sucesso!");
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Falha ao salvar delegação!");
          reject(err);
        });
    });
  };

  const getQuestoesFornecedores = async () => {
    incrementLoadingCount();

    try {
      const response = await api.get(`/questoes`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          User: user?.sub,
        },
      });
      setQuestoesFornecedores(response.data);
    } catch (error) {
      console.error("Error fetching fornecedores:", error);
    } finally {
      decrementLoadingCount();
    }
  };

  const getFornecedores = async (clientId: string) => {
    incrementLoadingCount();
    if (accessToken && user) {
      if (
        clientId &&
        userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        )
      ) {
        try {
          const response = await api.get(
            `/fornecedoresmodel/cliente/${clientId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                User: user?.sub,
              },
            }
          );
          setFornecedores(
            response.data.map((data: any, index: number) => {
              return {
                ...data,
                key: index,
              };
            })
          );
        } finally {
          decrementLoadingCount();
        }
      } else {
        try {
          const response = await api.get(`/fornecedoresmodel`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              User: user?.sub,
            },
          });
          setFornecedores(
            response.data.map((data: any, index: number) => {
              return {
                ...data,
                key: index,
              };
            })
          );
        } catch (error) {
          console.error("Error fetching fornecedores:", error);
        } finally {
          decrementLoadingCount();
        }
      }
    }
  };

  const getAllRespoForn = async () => {
    incrementLoadingCount();
    if (accessToken && user) {
      try {
        const response = await api.get(`/allrespostasforn`, {
          headers: { Authorization: `Bearer ${accessToken}`, User: user?.sub },
        });
        setAllRespFornecedor(response.data);
      } catch (error) {
        console.error("Error fetching fornecedores:", error);
      } finally {
        decrementLoadingCount();
      }
    }
  };

  const saveFeedback = async (
    userId: string,
    mensagem: string,
    screenshotUrl: string | null
  ) => {
    try {
      const response = await api.post(`/feedback`, {
        userId,
        mensagem,
        screenshotUrl,
      });
      message.success("Feedback salvo com sucesso!");
      return response.data;
    } catch (error) {
      message.error("Erro ao salvar feedback!");
    }
  };

  const updateFeedback = async (feedbackId: string, updatedFields: any) => {
    try {
      await api.put(`/feedback/${feedbackId}`, updatedFields);
      getFeedback();
    } catch (error) {}
  };
  const getFeedback = async () => {
    try {
      const response = await api.get(`/feedback`);
      setFeedbacks(response.data);
      return response.data;
    } catch (error) {}
  };

  const getAllQuestionarios = async () => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/allquestionarios`)
        .then((response) => {
          setAllQuestionarios(response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const postUserNoPermissions = async (values: any) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/usernopermissions`, {
          name: values.name,
          email: values.email,
          cliente_id: values.cliente_id,
        })
        .then((response) => {
          getUsuarios();
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao criar usuário!");
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const postAvaliacaoGovernanca = async (values: any) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/avaliacao/governanca`, {
          ...values,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao criar usuário!");
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const patchUser = async (values: any) => {
    try {
      incrementLoadingCount();
      const response = await api.patch(`/user/${values._id}`, {
        ...values,
      });
      getUsuarios();
      message.success("Usuário atualizado com sucesso!");
      return response.data;
    } catch (err) {
      message.error("Erro ao atualizar usuário!");
      throw err;
    } finally {
      decrementLoadingCount();
    }
  };
  const addUserWithGroupAndPermissions = async ({
    email,
    nome,
    grupoFuncoes,
  }: AddUserRequest) => {
    try {
      incrementLoadingCount();

      const response = await api.post(`/addUser`, {
        user: { name: nome, email },
        grupoFuncoes: {
          ...grupoFuncoes,
        },
      });

      getUsuarios();
      return response.data;
    } catch (err) {
      message.error("Erro ao criar usuário!");
    } finally {
      decrementLoadingCount();
    }
  };

  const getAnexo = async (fornecedorId: string) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/fornecedor/anexo/${fornecedorId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao buscar anexos!");
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const getAnexosOwasp = async (clienteId: string) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/anexo/owasp/${clienteId}`)
        .then((response) => {
          resolve(response.data);
          setAnexosOwasp(response.data);
        })
        .catch((err) => {
          setAnexosOwasp(null);

          message.error("Erro ao buscar anexos!");
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const postAnexoOwasp = async (file: any, clienteId: string, user: any) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append("anexo", file);
      formData.append("user_id", user);

      await api
        .post("/anexo/owasp/" + clienteId, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          getAnexosOwasp(clienteId);
          message.success("Relatória enviado com sucesso!");

          resolve(true);
        })
        .catch((err) => {
          message.error("Falha ao enviar relatório");

          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const postFornecedor = async (
    values: any,
    currentAnexos: any,
    clientId: string
  ) => {
    incrementLoadingCount();
    const formData = new FormData();

    if (currentAnexos) {
      for (const arquivo of currentAnexos) {
        formData.append("files", arquivo);
      }
    }

    const fornecedor: any = {
      cliente_id: clientId,
      nome: values.nome,
      area_id: values.area_id,
      representante: values.representante,
      cnpj: values.cnpj,
      email: values.email,
      telefone: values.telefone,
      type: values.type,
      _id: values._id,
    };

    Object.keys(fornecedor).forEach((key: any) => {
      formData.append(key, fornecedor[key]);
    });

    try {
      let response;

      if (fornecedor._id) {
        response = await api.patch(`/fornecedoresmodel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } else {
        response = await api.post(
          values.lead ? "/fornecedorlead" : `/fornecedoresmodel`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      getFornecedores(clientId);
      !values.lead && message.success("Fornecedores cadastrados com sucesso!");
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        message.error(
          values.lead
            ? "Erro, tente novamente mais tarde"
            : err.response.data.message
        );
        getFornecedores(clientId);
      } else {
        message.error(
          values.lead
            ? "Erro, tente novamente mais tarde"
            : "Erro ao cadastrar fornecedores!"
        );
      }
      throw err;
    } finally {
      decrementLoadingCount();
    }
  };

  const postFornecedoresImportados = async (
    importFile: any,
    clientId: string
  ) => {
    incrementLoadingCount();
    await api
      .post(`/fornecedoresmodelimport`, {
        fornecedores: [
          ...importFile.map((f: any) => {
            return {
              cliente_id: clientId,
              ...f,
            };
          }),
        ],
      })
      .then((response) => {
        getFornecedores(clientId);
        message.success("Fornecedores cadastrados com sucesso!");
      })
      .catch((err) => {
        message.error("Erro ao importar fornecedores!");
      })
      .finally(() => decrementLoadingCount());
  };

  const postCveCliente = async (
    data: any,
    clientId: string,
    nome: string,
    sponsor: any
  ) => {
    incrementLoadingCount();
    await api
      .post(`/cvecliente`, {
        data: data,
        cliente_id: clientId,
        nome: nome,
        sponsor: sponsor,
      })
      .then((response) => {
        // get
        message.success("Plano de ação criado com sucesso!");
      })
      .catch((err) => {
        message.error("Erro ao criar plano de ação!");
      })
      .finally(() => decrementLoadingCount());
  };

  const postUser = (values: any) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post("user", {
          ...values,
        })
        .then((response) => {
          getUsuarios();
          message.success("Usuário salvo com sucesso!");
          showAlert({
            type: "success",
            description: "Usuário salvo com sucesso!",
            message: "Sucesso!",
          });
          resolve(response);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message === "Sem vínculo com Tom Ticket"
              ? "Sem vínculo com Tom Ticket"
              : err?.response?.data?.message === 409
              ? "Este e-mail já está sendo utilizado!"
              : "Erro ao salvar usuário!"
          );
          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  const postUserRespondente = (usrInfo: any) => {
    incrementLoadingCount();
    api
      .post("usersrespondente", {
        name: usrInfo.name || usrInfo.email,
        email: usrInfo.email,
        area_id: usrInfo.area_id,
        questionario_id: usrInfo.questionario_id,
      })
      .then((response) => {
        getClientesRespondentes();
        showAlert({
          type: "success",
          description: "Usuário salvo com sucesso!",
          message: "Sucesso!",
        });
      })
      .catch((err) => {
        showAlert({
          type: "error",
          description: "Erro ao salvar usuário ou já cadastrado!",
          message: "Erro!",
        });
      })
      .finally(() => decrementLoadingCount());
  };

  // const getUser = () => {
  //   incrementLoadingCount();
  //   api
  //     .get("userinfo/" + user?.sub)
  //     .then((response) => {
  //       setUserInfo(response.data);
  //     })
  //     .catch((err) => {
  //       console.error("ops! ocorreu um erro" + err);
  //     })
  //     .finally(() => decrementLoadingCount());
  // };

  const getClientesRespondentes = () => {
    incrementLoadingCount();
    api
      .get("clientesrespondentes")
      .then((response) => {
        setClientesRespondentes(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getPlanoAcao = () => {
    incrementLoadingCount();
    api
      .get("planoacao")
      .then((response) => {
        setPlanoAcao(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const postPlanoAcao = (v: any) => {
    incrementLoadingCount();
    api
      .post("planoacao", {
        value: v.description,
        associacao_tbl_id: v.associacao_tbl_id,
        associacao_tbl_name: v.associacao_tbl_name,
      })
      .then((response) => {
        // getAreas();
        // getAnalise(v.quest_id);
        getPlanoAcao();
        showAlert({
          type: "success",
          description: "Plano de ação salvo com sucesso!",
          message: "Sucesso!",
        });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getPrazos = () => {
    incrementLoadingCount();
    api
      .get("prazos")
      .then((response) => {
        setPrazos(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const postResposta = (
    questionario_id: string,
    cliente_id: string,
    user_token: string,
    respostas: any,
    finalizar: boolean,
    fornecedor_id?: string // Assuming you have fornecedor_id available
  ) => {
    incrementLoadingCount();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post("respostas", {
          questionario_id: questionario_id,
          cliente_id: cliente_id,
          user_token: user_token,
          respostas: respostas,
          finalizar: finalizar,
          fornecedor_id: fornecedor_id,
        });
        processosContext?.getRespostas(user_token);
        showAlert({
          type: "success",
          description: "Respostas salvas com sucesso",
          message: "Sucesso!",
        });
        resolve(response.data);
      } catch (error) {
        showAlert({
          type: "error",
          description: "Prazo de respostas vencido",
          message: "Erro!",
        });
        reject(error);
      } finally {
        decrementLoadingCount();
      }
    });
  };

  const postRespostaGestor = (
    mapeamento: any,
    cliente_id: string,
    respostas: any,
    areaId: string
  ) => {
    incrementLoadingCount();
    api
      .post("respostasgestor", {
        questionario_id: mapeamento._id,
        cliente_id: cliente_id,
        respostas: respostas,
        area_id: areaId,
      })
      .then((response) => {
        processosContext?.getMultipleRespostasAuth(mapeamento.versoes);
        message.success("Respostas salvas com sucesso");
      })
      .catch((err) => {
        message.error("Falha ao salvar respostas");
      })
      .finally(() => decrementLoadingCount());
  };

  const fetchClienteLogs = (clientId: string) => {
    incrementLoadingCount();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.get(`/logs/cliente/${clientId}`);
        resolve(response.data);
      } catch (error) {
        message.error("Erro ao obter Logs!");
        reject(error);
      } finally {
        decrementLoadingCount();
      }
    });
  };

  const postLog = (
    title: string,
    description: string,
    cliente_id: string,
    prazo: any
  ) => {
    incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post(`log/${cliente_id}`, {
          title,
          description,
          ...(prazo && { prazo: prazo }),
        });

        // fetchClienteLogs(cliente_id);
        message.success("Registro salvo com sucesso!");
        resolve(response.data);
      } catch (error) {
        message.error("Falha ao salvar registro");
        reject();
      } finally {
        decrementLoadingCount();
      }
    });
  };

  const getOrganizacoes = async () => {
    incrementLoadingCount();
    await api
      .get(`/tomticketorganizacoes`)
      .then((res) => {
        setTomTicketOrganizacoes(res.data);
      })
      .catch((err) => {
        userInfo?.grupos?.find((gp: any) => gp.name === "assinante") &&
          message.error("Erro ao obter organizações Tom Ticket!");
      })
      .finally(() => {
        decrementLoadingCount();
      });
  };

  const getClientes = () => {
    incrementLoadingCount();
    api
      .get("cliente")
      .then((response) => {
        setClientes(response.data);

        if (
          !userInfo?.grupos?.find(
            (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
          )
        ) {
          setCurrentCliente(
            response.data?.find((c: any) => c._id === userInfo.cliente_id)
          );
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const showAlert = (v: AlertTypes) => {
    setAlert(v);
    setTimeout(function () {
      setAlert(undefined);
    }, 2500);
  };

  const getTiposCliente = () => {
    incrementLoadingCount();
    api
      .get("tiposcliente")
      .then((response) => {
        setTiposCliente(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const postPrimeiroAcesso = (user: any) => {
    incrementLoadingCount();
    api
      .post("primeiroacesso", {
        user: user,
      })
      .then((response) => {
        getUsuarios();
        message.success("Acesso enviado com sucesso para: " + user.name);
      })
      .catch((err) => {
        message.error("Falha ao enviar primeiro acesso para: " + user.name);
      })
      .finally(() => decrementLoadingCount());
  };

  const getGruposFunc = () => {
    incrementLoadingCount();
    api
      .get("gruposfuncoes")
      .then((response) => {
        setGruposFunc(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getFuncoes = () => {
    incrementLoadingCount();
    api
      .get("funcoes")
      .then((response) => {
        setFuncoes(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getAnalise = (qId: String) => {
    incrementLoadingCount();
    api
      .get("analise/" + qId, {
        headers: { Authorization: `Bearer ${accessToken}`, User: user?.sub },
      })
      .then((response) => {
        setCurrentAnalise(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getGrauRiscos = () => {
    incrementLoadingCount();
    api
      .get("grauriscos/", {
        headers: { Authorization: `Bearer ${accessToken}`, User: user?.sub },
      })
      .then((response) => {
        setGrauRiscos(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getGrauRiscosPergunta = () => {
    api
      .get("grauriscospergunta/", {
        headers: { Authorization: `Bearer ${accessToken}`, User: user?.sub },
      })
      .then((response) => {
        setGrauRiscosPergunta(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getModulos = () => {
    incrementLoadingCount();
    api
      .get("modulosativos")
      .then((response) => {
        setModulos(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getAuthUsers = () => {
    incrementLoadingCount();
    api
      .get("auth0/users")
      .then((response) => {
        setAuth0Users(response.data.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const getModulosPermissao = (cliente_id: string) => {
    incrementLoadingCount();
    api
      .get("modulopermissao/" + cliente_id)
      .then((response) => {
        setModulosPermissao(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => decrementLoadingCount());
  };

  const updatePermissaoModulo = async (
    clienteId: string,
    moduloId: string,
    inativo: boolean
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post("/modulopermissao", {
          cliente_id: clienteId || currentCliente?._id,
          modulo_id: moduloId,
          inativo: !inativo,
        });

        getModulosPermissao(clienteId);

        resolve(response.data);
      } catch (error) {
        message.error("Erro buscar módulos!");
        reject();
      } finally {
        decrementLoadingCount();
      }
    });
  };

  const atualizaStatusAcaoFornecedor = (
    value: string,
    fornecedor_id: string
  ) => {
    incrementLoadingCount();
    api
      .patch("statusacaofornecedor/", {
        fornecedor_id: fornecedor_id,
        status_acao: value,
      })
      .then((response) => {
        showAlert({
          type: "success",
          description: "Status atualizado!",
          message: "Sucesso!",
        });
      })
      .catch(() => {
        showAlert({
          type: "error",
          description: "Falha ao atualizar status!",
          message: "Erro!",
        });
      })
      .finally(() => decrementLoadingCount());
  };

  const saveCliente = (values: any, idCLiente?: string) => {
    incrementLoadingCount();
    if (idCLiente) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api.patch(`cliente/${idCLiente}`, values);
          getClientes();
          setCurrentCliente(response.data);
          message.success("Cliente atualizado com sucesso");
          resolve(response.data);
        } catch (error) {
          message.error("Erro atualizar cliente!");
          reject();
        } finally {
          decrementLoadingCount();
        }
      });
    } else {
      // salvar
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api.post("cliente", values);
          getClientes();
          message.success("Cliente salvo com sucesso");
          resolve(response.data);
        } catch (error) {
          message.error("Erro cadastrar cliente!");
          resolve({ success: false, error });
        } finally {
          decrementLoadingCount();
        }
      });
    }
  };

  const postVersaoRespostas = (questionario_id: string) => {
    incrementLoadingCount();
    api
      .post("versaorespostas", {
        questionario_id: questionario_id,
      })
      .then((response) => {
        if (currentCliente?._id) {
          mapeamentoContext?.getQuestionariosCliente(currentCliente._id);
        } else {
          mapeamentoContext?.getQuestionariosCliente(userInfo?.cliente_id);
        }
        message.success("Nova aplicação de questionário criada com sucesso!");
      })
      .catch((err) => {
        message.error("Falha ao criar nova aplicação de questionário!");
      })
      .finally(() => decrementLoadingCount());
  };

  const atualizaPrazoVersaoRespostas = (versaoId: string, prazo: any) => {
    incrementLoadingCount();
    api
      .put("versaorespostas/" + versaoId, {
        prazo: prazo,
      })
      .then((response) => {
        mapeamentoContext?.getQuestionariosCliente(
          currentCliente?._id || userInfo?.cliente_id
        );
        showAlert({
          type: "success",
          description: "Prazo atualizado com sucesso!",
          message: "Sucesso!",
        });
      })
      .catch((err) => {
        showAlert({
          type: "error",
          description: "Falha ao atualizar prazo!",
          message: "Falha!",
        });
      })
      .finally(() => decrementLoadingCount());
  };

  const createQuestionarioDeTemplate = (
    template_id: string,
    cliente_id: string,
    questionario_nome: string,
    sponsor: string
  ) => {
    incrementLoadingCount();
    api
      .post("questionariotemplate", {
        template_id: template_id,
        cliente_id: cliente_id,
        questionario_nome: questionario_nome,
        sponsor: sponsor,
      })
      .then((response) => {
        currentCliente
          ? mapeamentoContext?.getQuestionariosCliente(currentCliente._id)
          : mapeamentoContext?.getQuestionariosCliente(userInfo?.cliente_id);

        showAlert({
          type: "success",
          description: "Mapeamento aplicado com sucesso!",
          message: "Sucesso!",
        });
      })
      .catch((err) => {
        showAlert({
          type: "error",
          description: "Falha ao criar questionário!",
          message: "Erro!",
        });
      })
      .finally(() => decrementLoadingCount());
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    // Remova o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // O array vazio assegura que o efeito é executado apenas uma vez

  // Obter todos os CPPD
  const verificarCPPD = async (cliente_id: string) => {
    console.log(cliente_id);
    try {
      const response = await api.get(`/cppd`);
      const data = response.data;

      // Verificar se algum item em data tem o cliente_id correspondente
      const found = data.some((cppd: any) => cppd.cliente === cliente_id);

      if (found) {
        return data.find((cppd: any) => cppd.cliente === cliente_id);
      } else {
        return false;
      }
    } catch (error) {
      console.error("Erro ao verificar CPPD:", error);
      return false;
    }
  };

  const obterCppdPorId = async (cppdId: string) => {
    try {
      const response = await api.get(`/cppd/${cppdId}`);
      return response.data;
    } catch (error) {
      console.error("Erro ao obter CPPD:", error);
      return null;
    }
  };

  const criarCPPD = async (
    cliente_id: string,
    consultor_id: string,
    data_padrao: string,
    data_primeira_reuniao: string,
    intervalo: number,
    link: string
  ) => {
    try {
      const response = await api.post("/cppd", {
        cliente_id,
        consultor_id,
        data_padrao,
        data_primeira_reuniao,
        intervalo,
        link,
        transcricaoAta: "",
      });
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Função para obter as reuniões por ID do CPPD
  const obterReunioesPorCppd = async (cppdId: string) => {
    try {
      const response = await api.get(`/cppd/${cppdId}/reunioes`);
      return response.data;
    } catch (error) {
      console.error("Erro ao obter reuniões:", error);
      throw error;
    }
  };

  // Função para atualizar uma reunião por ID do CPPD e ID da Reunião
  const atualizarReuniaoPorId = async (
    cppdId: string,
    reuniaoId: string,
    dadosAtualizados: object
  ) => {
    try {
      const response = await api.patch(
        `/cppd/${cppdId}/reunioes/${reuniaoId}`,
        dadosAtualizados
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao atualizar a reunião:", error);
      throw error;
    }
  };

  const criarReuniaoTeams = async (
    cliente_id: string,
    date: any,
    title: string,
    cppd_id: string
  ) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post("/teams/reuniao", {
          cliente_id,
          date,
          title,
          cppd_id,
        })
        .then((response) => {
          message.success("Reunião extraordinária criada com sucesso!");

          resolve(response.data);
        })
        .catch((err) => {
          message.error("Falha ao criar reunião extraordinária");

          reject(err);
        })
        .finally(() => decrementLoadingCount());
    });
  };

  // Função para listar todas as pastas de um cofre no D4Sign
  const listarPastasCofreD4sign = async () => {
    try {
      const response = await api.get("/d4sign/folders"); // Utilize a rota do backend
      return response.data;
    } catch (error: any) {
      console.error("Erro ao listar as pastas do cofre:", error);
      throw error;
    }
  };

  // Função para criar pasta no cofre D4Sign
  const criarPastaNoCofreD4sign = async (folderName: string) => {
    try {
      const response = await api.post("/d4sign/folders/create", { folderName }); // Utilize a rota do backend
      console.log("Pasta criada com sucesso:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Erro ao criar pasta:", error);
      throw error;
    }
  };

  // Função para fazer upload de documento no D4Sign
  const uploadDocumentoD4sign = async (uuid_folder: string, file: any) => {
    try {
      const formData = new FormData();
      formData.append("uuid_folder", uuid_folder);

      // Certifique-se de usar `originFileObj` se estiver usando uma biblioteca de upload como antd
      const actualFile = file.originFileObj ? file.originFileObj : file;

      formData.append("file", actualFile); // Adiciona o arquivo correto ao FormData

      const response = await api.post("/d4sign/documents/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Upload realizado com sucesso:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Erro ao fazer upload do documento:", error);
      throw error;
    }
  };

  // Função para adicionar participantes ao documento no D4Sign
  const adicionarParticipantesD4sign = async (
    uuidDoc: string,
    participantes: Array<{ email: string; name: string }>
  ) => {
    try {
      const response = await api.post(
        `/d4sign/documents/${uuidDoc}/participants`,
        { participantes }
      );
      console.log("Participantes adicionados com sucesso:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Erro ao adicionar participantes ao documento:", error);
      throw error;
    }
  };

  // Função para enviar o documento aos signatários no D4Sign
  const enviarDocumentoParaAssinatura = async (uuidDoc: string) => {
    try {
      const response = await api.post(`/d4sign/documents/${uuidDoc}/send`);
      console.log(
        "Documento enviado para assinatura com sucesso:",
        response.data
      );
      return response.data;
    } catch (error: any) {
      console.error("Erro ao enviar o documento para assinatura:", error);
      throw error;
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        moduloProcessos: processosContext,
        moduloMapeamento: mapeamentoContext,
        moduloCsa: csaContext,
        moduloTask: taskContext,
        moduloNotificacao: notificacaoContext,
        moduloCppd: cppdCOntext,
        userInfo,
        loginToken,
        currentRoute,
        setCurrentRoute,
        windowWidth,
        setWindowWidth,
        postPlanoAcao,
        windowHeight,
        incrementLoadingCount,
        decrementLoadingCount,
        pageSize,
        pageSizeBig,
        funcoes,
        clientes,
        clienteAreas,
        auth0Users,
        setClientes,
        modulos,
        postFornecedor,
        grauRiscos,
        fetchClienteLogs,
        postUser,
        planoAcao,
        getAnalise,
        alert,
        setAlert,
        prazos,
        clientesRespondentes,
        getPlanoAcao,
        postCveCliente,
        currentAnalise,
        permissoes,
        anexosOwasp,
        setOpenKeys,
        openKeys,
        usuarios,
        postLog,
        postResposta,
        createQuestionarioDeTemplate,
        setUsuarios,
        getFornecedores,
        getAnexosOwasp,
        fornecedores,
        collapsed,
        postPrimeiroAcesso,
        setUserInfo,
        setCollapsed,
        getAnexo,
        tiposUser,
        showAlert,
        setFornecedores,
        tiposCliente,
        isAuthenticated,
        loadingCount,
        gruposFunc,
        atualizaPrazoVersaoRespostas,
        postFornecedoresImportados,
        postVersaoRespostas,
        postAnexoOwasp,
        saveCliente,
        setCurrentCliente,
        currentCliente,
        postUserNoPermissions,
        getAll,
        setAccessToken,
        accessToken,
        atualizaStatusAcaoFornecedor,
        postUserRespondente,
        clientesSponsor,
        allRespFornecedor,
        currentUser,
        setCurrentUser,
        addUserWithGroupAndPermissions,
        clientesLider,
        grauRiscosPergunta,
        updatePermissaoModulo,
        getGrauRiscosPergunta,
        modulosPermissao,
        getGrauRiscos,
        getModulosPermissao,
        setLoginToken,
        patchUser,
        tomTicketOrganizacoes,
        getUsuarios,
        editFornecedor,
        setEditFornecedor,
        saveFeedback,
        feedbacks,
        updateFeedback,
        postRespostaGestor,
        setRote,
        rote,
        postAvaliacaoGovernanca,
        questoesFornecedores,
        verificarCPPD,
        criarCPPD,
        obterReunioesPorCppd,
        atualizarReuniaoPorId,
        criarReuniaoTeams,
        obterCppdPorId,
        listarPastasCofreD4sign,
        criarPastaNoCofreD4sign,
        uploadDocumentoD4sign,
        adicionarParticipantesD4sign,
        enviarDocumentoParaAssinatura,
        delegarQuestionario,
        allQuestionarios,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
