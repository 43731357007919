import React, { useState, useEffect } from "react";
import {
  Avatar,
  Space,
  Tooltip,
  Modal,
  Tag,
  Dropdown,
  Checkbox,
  Divider,
  Button,
  theme,
  Select,
  Input,
  Spin,
  DatePicker,
  Table,
} from "antd";
import {
  CalendarOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import "./styles.css";
import Comments from "../../Comments/Comments";
import { ModalVerTarefaTypes } from "../../../@types/modalVerTarefa";
import NewUser from "../../NewUser/NewUser";
import { useParams } from "react-router-dom";
import { TaskType } from "../../../context/taskContext";
import moment from "moment";

const { useToken } = theme;
const { Search } = Input;

const Task: React.FC<ModalVerTarefaTypes> = ({ showModal, setShowModal }) => {
  const { userInfo, usuarios, moduloTask, currentCliente, clientes } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [searchValue, setSearchValue] = useState("");
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState<any>(null); // Estado para a nova data
  const [isEditing, setIsEditing] = useState(false); // Estado para controlar o modo de edição

  const handleDateChange = (date: any) => {
    setNewDate(date); // Atualiza o estado com a data selecionada
  };

  const updateDeadline = () => {
    if (newDate) {
      const cliente_id =
        moduloTask.selectedTask?.cliente_id ||
        currentCliente?._id ||
        usuarios?.find(
          (u: any) => u._id === moduloTask.selectedTask?.assignment_id
        )?.cliente_id ||
        usuarios?.find((u: any) => u._id === moduloTask.selectedTask?.owner_id)
          ?.cliente_id;

      moduloTask.selectedTask &&
        moduloTask.updateTask({
          ...moduloTask.selectedTask,
          prazo_conclusao: newDate,
          cliente_id: cliente_id,
        });
      setNewDate(null); // Limpa a seleção de data após a atualização
      setIsEditing(false); // Sai do modo de edição
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const params = useParams();

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (usuarios) {
      setFilteredUsuarios(
        usuarios.filter((usr: any) =>
          usr.name?.toLowerCase()?.includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, usuarios]);
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "300px",
    maxHeight: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const taskCorretas = (taskCli: TaskType[], other: TaskType[]) => {
    const tasksCliente = taskCli?.filter(
      (task: any) =>
        task.tipo_dependencia === "checklist" &&
        task.dependencia === moduloTask.selectedTask?._id
    );

    const otherTasks = other?.filter(
      (task: any) =>
        task.tipo_dependencia === "checklist" &&
        task.dependencia === moduloTask.selectedTask?._id
    );

    if (tasksCliente?.[0]) {
      return tasksCliente;
    } else {
      return otherTasks;
    }
  };

  const handleMenuClick = (e: any) => {
    moduloTask.selectedTask &&
      moduloTask.updateTask({
        ...moduloTask.selectedTask,
        status: e.key,
        cliente_id:
          moduloTask.selectedTask?.cliente_id ||
          currentCliente?._id ||
          usuarios?.find(
            (u: any) => u._id === moduloTask.selectedTask?.assignment_id
          )?.cliente_id ||
          usuarios?.find(
            (u: any) => u._id === moduloTask.selectedTask?.owner_id
          )?.cliente_id,
      });
  };

  const items: any = [
    {
      key: "Pendente",
      label: "Pendente",
      onClick: handleMenuClick,
    },
    {
      key: "Em andamento",
      label: "Em andamento",
      onClick: handleMenuClick,
    },
    {
      key: "Concluída",
      label: "Concluída",
      onClick: handleMenuClick,
    },
  ];

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  const columns = [
    {
      title: "Cliente",
      key: "cliente",
      render: () =>
        clientes?.find(
          (c: any) =>
            c._id ===
            (moduloTask.selectedTask?.cliente_id ||
              currentCliente?._id ||
              usuarios?.find(
                (u: any) => u._id === moduloTask.selectedTask?.assignment_id
              )?.cliente_id ||
              usuarios?.find(
                (u: any) => u._id === moduloTask.selectedTask?.owner_id
              )?.cliente_id)
        )?.razao_social || "-",
    },
    {
      title: "Atribuído",
      key: "atribuido",
      render: () => (
        <div
          className="microtask_user"
          style={{
            // cursor: "pointer",
            width: "fit-content",
          }}
        >
          <Avatar
            className="avatar-task"
            src={`https://ui-avatars.com/api/?name=${moduloTask.selectedTask?.assignment_name?.replaceAll(
              " ",
              "+"
            )}?size=128?background=ebebeb`}
          />
          <p>
            {moduloTask.selectedTask?.assignment_name ||
              usuarios?.find(
                (u: any) => u._id === moduloTask.selectedTask?.assignment_id
              )?.name ||
              ""}
          </p>

          <Tooltip title="Alterar atribuíção">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {loading && (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
              )}
              <Dropdown
                placement="bottom"
                disabled={loading}
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      type: "group",
                      label: "Consultores",
                      children: usuarios
                        ?.filter(
                          (usr: any) =>
                            usr.grupos.find(
                              (g: any) =>
                                g.name === "seusdados" || g.name === "assinante"
                            ) && usr.cliente_id === "64e648ff902fd5e820397ebb" // Seusdados
                        )
                        ?.map((usr: any) => {
                          return {
                            key: usr._id,
                            label: usr.name,
                            onClick: (v: any) => {
                              if (moduloTask?.selectedTask?._id) {
                                setLoading(true);
                                moduloTask
                                  .updateTaskAssignment(
                                    moduloTask?.selectedTask?._id,
                                    usr._id
                                  )
                                  .then((data) =>
                                    moduloTask.setSelectedTask(data)
                                  )
                                  .finally(() => setLoading(false));
                              }
                            },
                          };
                        }),
                    },
                  ],
                }}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    <Search
                      style={{
                        margin: "10px 18px",
                        width: "264px",
                      }}
                      placeholder="Buscar"
                      onSearch={handleSearch}
                      enterButton
                    />
                    <Divider style={{ margin: 0 }} />
                    {React.cloneElement(menu as React.ReactElement, {
                      style: menuStyle,
                    })}
                    <Divider style={{ margin: 0 }} />
                    <Space
                      style={{
                        padding: 8,
                        justifyContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button
                        type="primary"
                        icon={<UserAddOutlined />}
                        onClick={() => setShowModalNewUser(true)}
                      >
                        Novo usuário
                      </Button>
                    </Space>
                  </div>
                )}
              >
                <EditOutlined style={{ marginLeft: "0.5rem", color: "#000" }} />
              </Dropdown>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Criado por",
      key: "criadopor",
      render: () => (
        <div className="microtask_user">
          <Avatar
            className="avatar-task"
            src={`https://ui-avatars.com/api/?name=${moduloTask.selectedTask?.owner_name?.replaceAll(
              " ",
              "+"
            )}?background=ebebeb`}
          />
          <p>
            {moduloTask.selectedTask?.owner_name ||
              usuarios?.find(
                (u: any) => u._id === moduloTask.selectedTask?.owner_id
              )?.name ||
              ""}
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={
          moduloTask.selectedTask
            ? moduloTask.selectedTask.tipo === "pauta"
              ? moduloTask.selectedTask.title
              : "Tarefa: " + moduloTask.selectedTask.title
            : ""
        }
        // title={selectedTask ? "Tarefa: Capitulo " + selectedTask.title : ""}
        open={showModal}
        style={{ maxWidth: 900 }}
        footer={[]}
        width={"90%"}
        onCancel={handleShowModal}
      >
        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        ) ? (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Space style={{ cursor: "pointer" }}>
              <Tag
                style={{
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
                color={
                  moduloTask.selectedTask?.status === "Em andamento"
                    ? "blue"
                    : moduloTask.selectedTask?.status === "Concluída"
                    ? "green"
                    : "grey"
                }
              >
                {moduloTask.selectedTask?.status}
              </Tag>
              <EditOutlined />
            </Space>
          </Dropdown>
        ) : (
          <Tag
            style={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
            color={
              moduloTask.selectedTask?.status === "Em andamento"
                ? "blue"
                : moduloTask.selectedTask?.status === "Concluída"
                ? "green"
                : "grey"
            }
          >
            {moduloTask.selectedTask?.status}
          </Tag>
        )}
        {moduloTask?.selectedTask?.tipo !== "pauta" && (
          <Table
            style={{ marginTop: "1rem" }}
            size="small"
            dataSource={[{}]}
            columns={columns}
            pagination={false}
            bordered
          />
        )}

        <p
          style={{
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          Descrição
        </p>
        <p
          style={{
            maxHeight: "20rem",
            minHeight: "5rem",
            marginTop: "0.5rem",
            overflowY: "auto",
            background: "#F3F2F1",
            borderRadius: "10px",
            padding: "1rem",
          }}
          dangerouslySetInnerHTML={{
            __html:
              moduloTask?.selectedTask?.description?.replaceAll(
                "\n",
                "<br />"
              ) || "",
          }}
        ></p>
        <>
          {moduloTask?.selectedTask?.prazo_conclusao && (
            <>
              <p
                style={{
                  marginTop: "1rem",
                  fontWeight: "bold",
                }}
              >
                Prazo
              </p>

              <Tooltip title={isEditing ? "Cancelar edição" : "Editar prazo"}>
                <p
                  style={{
                    color: "#5f29cc",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={handleEditClick}
                >
                  <b>
                    <CalendarOutlined />{" "}
                    {moment(moduloTask?.selectedTask?.prazo_conclusao).format(
                      "DD/MM/YYYY"
                    )}
                    <EditOutlined
                      style={{ marginLeft: "0.5rem", color: "#000" }}
                    />
                  </b>
                </p>
              </Tooltip>
            </>
          )}

          {isEditing && ( // Exibe o componente de edição apenas no modo de edição
            <div style={{ marginTop: "1rem" }}>
              <DatePicker
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                value={newDate}
                size="small"
                disabledDate={disabledDate}
                placeholder="Selecione uma nova data"
              />
              <Button
                type="primary"
                size="small"
                onClick={updateDeadline}
                style={{ marginLeft: "1rem" }}
                disabled={!newDate}
              >
                Atualizar Prazo
              </Button>
            </div>
          )}
        </>

        {(moduloTask.tasksCliente?.filter(
          (task: any) =>
            task.tipo_dependencia === "checklist" &&
            task.dependencia === moduloTask.selectedTask?._id
        )[0] ||
          moduloTask.tasks?.filter(
            (task: any) =>
              task.tipo_dependencia === "checklist" &&
              task.dependencia === moduloTask.selectedTask?._id
          )[0]) && (
          <h3
            style={{
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            Lista de verificação
          </h3>
        )}

        {taskCorretas(moduloTask.tasksCliente, moduloTask.tasks)
          ?.filter(
            (task: any) =>
              task.tipo_dependencia === "checklist" &&
              task.dependencia === moduloTask.selectedTask?._id
          )
          ?.map((task: any) => {
            return (
              <div className="microtask_container">
                <Tooltip title="Ver tarefa">
                  <EyeOutlined
                    onClick={() => {
                      moduloTask.setSelectedTask(task);
                    }}
                    style={{
                      cursor: "pointer",
                      marginRight: "0.5rem",
                      color: "#1677ff",
                    }}
                  />
                </Tooltip>

                {/* <Tooltip title="Concluir tarefa">
                  <Checkbox>{task.title}</Checkbox>
                </Tooltip> */}
                <p style={{ maxWidth: "600px" }}>{task.title}</p>
                <Tooltip title="Alterar atribuíção">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                    }}
                  >
                    {loading && (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 16 }} spin />
                        }
                      />
                    )}
                    <Dropdown
                      placement="bottom"
                      disabled={loading}
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            type: "group",
                            label: "Sponsors",
                            children: usuarios
                              ?.filter((usr: any) =>
                                usr.grupos.find(
                                  (g: any) => g.name === "sponsor"
                                )
                              )
                              ?.filter((usr: any) =>
                                params.id ? usr.cliente_id === params.id : true
                              )
                              ?.map((usr: any) => {
                                return {
                                  key: usr._id,
                                  label: usr.name,
                                  onClick: (v: any) => {
                                    setLoading(true);
                                    moduloTask
                                      .updateTaskAssignment(task._id, usr._id)
                                      .finally(() => setLoading(false));
                                  },
                                };
                              }),
                          },
                          {
                            key: "2",
                            type: "group",
                            label: "Outros",
                            children: usuarios
                              ?.filter((usr: any) =>
                                usr.grupos.find(
                                  (g: any) => g.name !== "sponsor"
                                )
                              )
                              ?.filter((usr: any) =>
                                params.id ? usr.cliente_id === params.id : true
                              )
                              ?.map((usr: any) => {
                                return {
                                  key: usr._id,
                                  label: usr.name,
                                  onClick: (v: any) => {
                                    setLoading(true);
                                    moduloTask
                                      .updateTaskAssignment(task._id, usr._id)
                                      .finally(() => setLoading(false));
                                  },
                                };
                              }),
                          },
                        ],
                      }}
                      dropdownRender={(menu) => (
                        <div style={contentStyle}>
                          <Search
                            style={{
                              margin: "10px 18px",
                              width: "264px",
                            }}
                            placeholder="Buscar"
                            onSearch={handleSearch}
                            enterButton
                          />
                          <Divider style={{ margin: 0 }} />
                          {React.cloneElement(menu as React.ReactElement, {
                            style: menuStyle,
                          })}
                          <Divider style={{ margin: 0 }} />
                          <Space
                            style={{
                              padding: 8,
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <Button
                              type="primary"
                              icon={<UserAddOutlined />}
                              onClick={() => setShowModalNewUser(true)}
                            >
                              Novo usuário
                            </Button>
                          </Space>
                        </div>
                      )}
                    >
                      <div
                        className="microtask_user"
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                      >
                        <Avatar
                          className="avatar-task"
                          src={`https://ui-avatars.com/api/?name=${task?.assignment_name?.replaceAll(
                            " ",
                            "+"
                          )}?background=ebebeb`}
                        />
                        <p>{task?.assignment_name || ""}</p>
                        <EditOutlined style={{ marginLeft: "0.5rem" }} />
                      </div>
                    </Dropdown>
                  </div>
                </Tooltip>
              </div>
            );
          })}

        {!moduloTask.selectedTask?.title?.includes("pauta") && (
          <>
            <p
              style={{
                marginTop: "1rem",
                marginBottom: "-2.5rem",
                fontWeight: "bold",
              }}
            >
              Comentários
              {/* {moduloTask.selectedTask?.tipo === "pauta" && "Comentários"} */}
              {/* {moduloTask.selectedTask?.tipo !== "pauta" && "Ações rápidas"} */}
            </p>
            {moduloTask.selectedTask && moduloTask.selectedTask._id && (
              <Comments task_id={moduloTask.selectedTask._id} />
            )}
          </>
        )}
      </Modal>
      <NewUser
        showModal={showModalNewUser}
        setShowModal={setShowModalNewUser}
      />
    </>
  );
};

export default Task;
